import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { FormInput } from "components/formInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthFormValues } from "utils/types/auth.type";
import { Link as ReachLink } from "react-router-dom";
import bannerImage from "assets/images/bannerImage.png";
import Logo from "assets/images/logo.png";
import { BiHide, BiShow } from "react-icons/bi";

export default function ForgetPassword() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AuthFormValues>();

  const onSubmit: SubmitHandler<AuthFormValues> = (values) => {
    console.log(values);
    // if (step === '1' && isAgree) {
    //   checkEmailExist(values?.email)
    // } else if (step === '2') {
    //   if (state?.user || loginUser) {
    //     providerSignUp({
    //       ...values,
    //       email: state?.user?.email || loginUser?.email || '',
    //       accessToken: state?.user?.accessToken || loginUser?.accessToken,
    //       loginType: 'GOOGLE',
    //       profilePicURL: state?.user?.photoURL || loginUser?.photoURL,
    //     })
    //   } else {
    //     signUp(values)
    //   }
    // }
  };

  const [step, setStep] = useState(1);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <Grid
      bg="white"
      gridTemplateColumns={{ lg: "1fr 1fr" }}
      gap="40px"
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
    >
      <Grid
        alignItems="center"
        justifyContent="center"
        overflow="auto"
        minH="100vh"
        maxH="100vh"
      >
        <Box p="15px" textAlign="center">
          <ReachLink to="/">
            <Image
              src={Logo}
              mb="32px"
              w="80px"
              h="100px"
              display="block"
              mx="auto"
            />
          </ReachLink>
          {step === 1 && (
            <>
              {" "}
              <Heading fontWeight={700}>Login</Heading>
              <Text my="30px">Please enter the associated email</Text>
              <Box
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                mt="40px"
                maxW="500px"
              >
                <Text color="secondary" textAlign="start" mb="12px">
                  Email
                </Text>
                <FormInput<AuthFormValues>
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Enter valid email.",
                    },
                  }}
                  placeholder="Email"
                  name="email"
                  register={register}
                  isError={Boolean(errors.email)}
                  errorMessage={errors.email && errors.email.message}
                />

                <Button
                  w={{ base: "330px", md: "400px" }}
                  my="2rem"
                  type="submit"
                  variant="secondary"
                  onClick={() => setStep(2)}
                >
                  Get Code
                </Button>
              </Box>
            </>
          )}
          {step === 2 && (
            <>
              <Heading fontWeight={700}>Email OTP Verification</Heading>
              <Text my="30px">6 digits code has been sent to</Text>
              <Text my="30px" color="primary.500">
                user***@email.com
              </Text>
              <Text my="1.8rem">Time Remaining 02:00</Text>
              <HStack justify="center" mb="2rem">
                <PinInput otp size="lg" focusBorderColor="primary.500">
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <HStack justify="center" mb="2rem">
                <Text>Not Received?</Text>
                <Button variant="ghost" color="primary.500" px="0" _hover={{}}>
                  Send Again
                </Button>
              </HStack>
              <ButtonGroup w="100%" spacing="12px">
                <Button w="50%" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button w="50%" variant="secondary" onClick={() => setStep(3)}>
                  Verify
                </Button>
              </ButtonGroup>
            </>
          )}
          {step === 3 && (
            <>
              {" "}
              <Heading fontWeight={700}>Add New Password</Heading>
              <Text my="30px">
                Enter a strong & secure password of at least 8 characters.
              </Text>
              <Box
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                mt="40px"
                maxW="500px"
              >
                <Text color="secondary" textAlign="start" mb="12px">
                  New Password
                </Text>
                <FormControl isInvalid={Boolean(errors.password)} mb="25px">
                  <InputGroup size="lg">
                    <Input
                      bg="white"
                      focusBorderColor="primary.500"
                      {...register("password", {
                        required: "Password is required",
                        validate: {
                          isCharacter: (value) =>
                            // eslint-disable-next-line
                            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
                              value
                            ) || "Must contain one Special character",
                          // eslint-disable-next-line
                          isSmall: (value) =>
                            /[a-z]/.test(value) ||
                            "Must contain one Small letter",
                          isCapital: (value) =>
                            /[A-Z]/.test(value) ||
                            "Must contain one Capital character",
                          isDigit: (value) =>
                            /\d/.test(value) ||
                            "Must contain one Digit character",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length should be 8",
                        },
                      })}
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder="Password"
                      autoComplete="new-password"
                    />
                    <InputRightElement
                      // eslint-disable-next-line react/no-children-prop
                      children={
                        <Icon
                          as={show ? BiShow : BiHide}
                          color="gray.400"
                          onClick={handleClick}
                        />
                      }
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  w="100%"
                  my="2rem"
                  type="submit"
                  variant="secondary"
                  onClick={() => setStep(1)}
                >
                  Update Password
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Box
        as={Flex}
        position="relative"
        display={{ base: "none", lg: "flex" }}
        minH="100vh"
        maxH="100vh"
      >
        <Image w="100%" h="100%" objectFit="cover" src={bannerImage} />
      </Box>
    </Grid>
  );
}
