import React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Text,
  HStack,
  UnorderedList,
  ListItem,
  InputGroup,
  InputLeftElement,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import WalletBg from "assets/images/wallet-bg.png";
import PrimaryLogo from "assets/images/primary-logo.svg";
import { ChevronDownIcon, ChevronRightIcon, CopyIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { CongratsIcon, GreenWallet } from "assets/icons";
import { CREATE_WALLET_STEPS } from "utils/const";

export default function CreateWallet() {
  const [walletType, setWalletType] = useState("");
  return (
    <Box
      minH="100vh"
      bg={`url(${WalletBg}) no-repeat center`}
      bgSize="cover"
      px="20px"
      py="50px"
    >
      <UserProfile />
      {!walletType && <WelcomeScreen setWalletType={setWalletType} />}
      {walletType === "new" && <NewWallet />}
      {walletType === "import" && <ImportWallet />}
    </Box>
  );
}

function UserProfile() {
  return (
    <Flex
      border="2px solid #EEEEEE"
      borderRadius="32px"
      maxW="275px"
      ml="auto"
      bg="white"
      p="8px"
      align="center"
      gap="15px"
    >
      <Avatar />
      <Box>
        <Text color="secondary">Marvin McKinney</Text>
        <Text fontSize="13px">marvin00@email.com</Text>
      </Box>
      <Center ml="auto" w="28px" h="28px" rounded="50%" bg="blackAlpha.200">
        <ChevronDownIcon color="secondary" />
      </Center>
    </Flex>
  );
}

function WelcomeScreen({
  setWalletType,
}: {
  setWalletType: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Center
      minH="calc(100vh - 167px)"
      flexDirection="column"
      gap="30px"
      maxW="560px"
      mx="auto"
    >
      <Heading>{`Let's`} get started </Heading>
      <Text textAlign="center">
        Embraced by countless users, Grow my fund stands as a fortified vault,
        enabling world access to the realm of web3.
      </Text>
      <Image src={PrimaryLogo} alt="Primary Logo" />
      <Button variant="secondary" onClick={() => setWalletType("new")}>
        Create A New Wallet
      </Button>
      <Button onClick={() => setWalletType("import")}>
        Import Sn Existing Wallet
      </Button>
    </Center>
  );
}

function NewWallet() {
  const [privateKey, setPrivateKey] = useState(
    "spring prosecution admiration relinquish bother headline bubble miner care memory farewell colorful"
  );
  const [userPrivateKey, setUserPrivateKey] = useState(new Array(12).fill(""));
  const [isSuccess, setIsSuccess] = useState(false);
  const handlePrivateKey = (val: string, index: number) => {
    const updatedArr = [...userPrivateKey];
    updatedArr[index] = val;
    setUserPrivateKey(updatedArr);
  };
  const { onToggle, isOpen: isVewKey } = useDisclosure();

  return (
    <Box mt="40px">
      <HStack
        divider={<ChevronRightIcon border="none" color="secondary" />}
        spacing={4}
        align="center"
        justify="center"
      >
        {CREATE_WALLET_STEPS?.map((label, index) => (
          <StepUI
            label={label}
            num={index + 1}
            key={label}
            isActive={index === (privateKey ? 0 : isSuccess ? 2 : 1)}
          />
        ))}
      </HStack>
      {isSuccess ? (
        <SuccessFull title="Wallet created successfully" />
      ) : (
        <Box maxW="620px" mx="auto" mt="60px">
          <Heading fontSize="48px" textAlign="center">
            {privateKey
              ? "Write down your secret recovery phrase"
              : "Confirm secret recovery phrase"}
          </Heading>
          {privateKey && (
            <Text textAlign="center" my="35px">
              Please record this 12-word Secret Recovery Phrase and store it
              securely in a location you trust, ensuring that it remains
              accessible exclusively to you.
            </Text>
          )}
          {privateKey && (
            <Box rounded="8px 8px 0px 0px" bg="secondaryText" overflow="hidden">
              <Text p="15px" variant="primary" bg="primary.500" fontSize="20px">
                Some Useful Tips
              </Text>
              <UnorderedList p="25px 15px">
                <ListItem>
                  If I lose my secret phrase, my funds will be lost forever.
                </ListItem>
                <ListItem>
                  If I expose or share my secret phrase to anybody, my funds can
                  get stolen.
                </ListItem>
                <ListItem>
                  Grow My Fund support will NEVER reach out to ask for it.
                </ListItem>
              </UnorderedList>
            </Box>
          )}
          <Flex
            mt="30px"
            bg="white"
            rounded="8px"
            border="1px solid #9DA2A8"
            p="30px"
            wrap="wrap"
            gap="24px"
          >
            {privateKey
              ? privateKey
                  ?.split(" ")
                  ?.map((key, index) => (
                    <KeyInput
                      num={index + 1}
                      key={key}
                      value={key}
                      isView={isVewKey}
                      readOnly
                    />
                  ))
              : userPrivateKey?.map((key, index) => (
                  <KeyInput
                    num={index + 1}
                    key={index + 1}
                    value={key}
                    isView={isVewKey}
                    handleKeyChange={(val) => handlePrivateKey(val, index)}
                  />
                ))}
          </Flex>
          <Flex my="30px" justify="space-between">
            <Button
              variant="link"
              color="text"
              leftIcon={
                isVewKey ? (
                  <BiShow fontSize="18px" />
                ) : (
                  <BiHide fontSize="18px" />
                )
              }
              onClick={() => onToggle()}
            >
              {isVewKey ? "Show" : "Hide"} seed phrase
            </Button>
            {privateKey && (
              <Button variant="link" color="text" leftIcon={<CopyIcon />}>
                Copy to clipboard
              </Button>
            )}
          </Flex>
          <Button
            variant="secondary"
            maxW="380px"
            w="100%"
            mx="auto"
            display="flex"
            onClick={() =>
              privateKey ? setPrivateKey("") : setIsSuccess(true)
            }
          >
            {privateKey ? "Next" : "Confirm"}
          </Button>
        </Box>
      )}
    </Box>
  );
}

function ImportWallet() {
  const [userPrivateKey, setUserPrivateKey] = useState(new Array(12).fill(""));
  const [isSuccess, setIsSuccess] = useState(false);
  const handlePrivateKey = (val: string, index: number) => {
    const updatedArr = [...userPrivateKey];
    updatedArr[index] = val;
    setUserPrivateKey(updatedArr);
  };
  const { onToggle, isOpen: isVewKey } = useDisclosure();

  return (
    <Box mt="40px">
      <HStack
        divider={<ChevronRightIcon border="none" color="secondary" />}
        spacing={4}
        align="center"
        justify="center"
      >
        {CREATE_WALLET_STEPS?.filter((item) => item !== "Secure Wallet")?.map(
          (label, index) => (
            <StepUI
              label={label}
              num={index + 1}
              key={label}
              isActive={index === (isSuccess ? 1 : 0)}
            />
          )
        )}
      </HStack>
      {isSuccess ? (
        <SuccessFull title="Wallet imported successfully" />
      ) : (
        <Box maxW="620px" mx="auto" mt="60px">
          <Heading fontSize="48px" textAlign="center">
            Confirm secret recovery phrase
          </Heading>
          <Text textAlign="center" my="35px">
            Confirm secret recovery phrase
          </Text>
          <Flex
            mt="30px"
            bg="white"
            rounded="8px"
            border="1px solid #9DA2A8"
            p="30px"
            wrap="wrap"
            gap="24px"
          >
            {userPrivateKey?.map((key, index) => (
              <KeyInput
                num={index + 1}
                key={index + 1}
                value={key}
                isView={isVewKey}
                handleKeyChange={(val) => handlePrivateKey(val, index)}
              />
            ))}
          </Flex>
          <Flex my="30px" justify="space-between">
            <Button
              variant="link"
              color="text"
              leftIcon={
                isVewKey ? (
                  <BiShow fontSize="18px" />
                ) : (
                  <BiHide fontSize="18px" />
                )
              }
              onClick={() => onToggle()}
            >
              {isVewKey ? "Show" : "Hide"} seed phrase
            </Button>
          </Flex>
          <Button
            variant="secondary"
            maxW="380px"
            w="100%"
            mx="auto"
            display="flex"
            onClick={() => setIsSuccess(true)}
          >
            Confirm
          </Button>
        </Box>
      )}
    </Box>
  );
}

function StepUI({
  isActive,
  label,
  num,
}: {
  isActive: boolean;
  label: string;
  num: number;
}) {
  return (
    <Flex gap="20px" align="center">
      <Center
        as={Text}
        variant="primary"
        h="28px"
        w="28px"
        bg={isActive ? "primary.500" : "#C5C5C5"}
        rounded="50%"
      >
        {num}
      </Center>
      <Text color={isActive ? '"secondary"' : "#C5C5C5"}>{label}</Text>
    </Flex>
  );
}

function KeyInput({
  num,
  value,
  isView,
  readOnly,
  handleKeyChange,
}: {
  num: number;
  value: string;
  isView: boolean;
  readOnly?: boolean;
  handleKeyChange?: (val: string) => void;
}) {
  return (
    <InputGroup
      border="1px solid"
      borderColor={value ? "primary.500" : "secondaryText"}
      borderRadius="32px"
      maxW="170px"
    >
      <InputLeftElement
        top="6px"
        left="6px"
        bg={value ? "primary.500" : "secondaryText"}
        w="28px"
        h="28px"
        rounded="50%"
        color="white"
      >
        {num}
      </InputLeftElement>
      <Input
        type={isView ? "password" : "text"}
        border="none"
        outline="none"
        focusBorderColor="transparent"
        defaultValue={value}
        readOnly={readOnly}
        onChange={(e) => handleKeyChange?.(e.target.value)}
      />
    </InputGroup>
  );
}

function SuccessFull({ title }: { title: string }) {
  return (
    <Center flexDirection="column" gap="30px" mt="30px">
      <CongratsIcon />
      <Heading>{title}</Heading>
      <Text>
        Congratulations your Grow My Fund Wallet has been created successfully.
      </Text>
      <GreenWallet />
      <Button variant="secondary" maxW="380px" w="100%">
        Invest Now
      </Button>
    </Center>
  );
}
