import React from "react";
import logo from "assets/images/logo.svg";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Flex,
  Text,
  Image,
  Button,
  IconButton,
  Box,
  Container,
} from "@chakra-ui/react";
import { MENU } from "utils/const";
import { Link } from "react-router-dom";
import { useIsLogin } from "hooks";
import { HamburgerIcon } from "@chakra-ui/icons";

export default function Header() {
  const isLogin = useIsLogin();
  const intoView = (id: string) => {
    const elem = document.getElementById(id);
    elem?.scrollIntoView({ behavior: "smooth" });
  };
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  return (
    <Box as="header" position="absolute" w="100%" top="0">
      <Container maxW="1280px">
        <Flex py="30px" alignItems="center" gap="25px">
          <Link to="/">
            <Image src={logo} />
          </Link>
          {isLargerThan960 ? (
            <Flex as="nav" ml="auto" gap="25px" align="center">
              {MENU.map(({ label, path }) => (
                <Text
                  onClick={() => intoView(path)}
                  as={Link}
                  to={path}
                  key={label}
                  variant="primary"
                >
                  {label}
                </Text>
              ))}
            </Flex>
          ) : (
            <MobileMenu onClick={intoView} />
          )}
          {!isLogin ? (
            <Button to="/login" as={Link} fontSize="14px">
              Login
            </Button>
          ) : (
            <Button to="/dashboard" as={Link} fontSize="14px">
              Dashboard
            </Button>
          )}
        </Flex>
      </Container>
    </Box>
  );
}

function MobileMenu({ onClick }: { onClick: (path: string) => void }) {
  return (
    <Menu>
      <MenuButton
        ml="auto"
        as={IconButton}
        aria-label="menu-icon"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        {MENU.map(({ label, path }) => (
          <MenuItem
            onClick={() => onClick(path)}
            as={Link}
            to={path}
            key={label}
            mr="1rem"
          >
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
