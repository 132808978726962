export const MENU = [
  {
    label: "Home",
    path: "#home",
  },
  {
    label: "How It Works",
    path: "#how-it-works",
  },
  {
    label: "Staking Info",
    path: "#stake-info",
  },
  {
    label: "About Us",
    path: "#about",
  },
];

export const CREATE_WALLET_STEPS = [
  "Secure Wallet",
  "Confirm Secret Recovery Phrase",
  "Successful",
];
