import React from "react";
import {
  Box,
  Container,
  Center,
  Heading,
  Text,
  Highlight,
  ButtonGroup,
  Button,
  Grid,
  Card,
  Flex,
  Divider,
  Avatar,
  Image,
  List,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import Header from "components/header";
import banner from "assets/images/banner.png";
import aboutUS from "assets/images/about-us.png";
import stakeInfoImg from "assets/images/stake-info.png";
import prviderBg from "assets/images/prvider-bg.png";
import laptopImg from "assets/images/laptop-img.png";
import calculatorBg from "assets/images/calculator-bg.png";
import GraphImg from "assets/images/graph.png";
import { AddIcon, ArrowForwardIcon, MinusIcon } from "@chakra-ui/icons";
import {
  BUSDCoin,
  BUSDLogo,
  DollarCoin,
  InvestorPercentIcon,
  StakedAmountIcon,
  TUSDCoin,
  USDTLogo,
  UserIcon,
  WalletIcon,
  WithdrawIcon,
} from "assets/icons";
import Chart from "react-apexcharts";
import Footer from "components/footer";
import FAQS from "utils/data/faq.json";
export default function Landing() {
  return (
    <>
      <Header />
      <Box
        as="section"
        bg={`url(${banner}) no-repeat center`}
        bgSize="cover"
        minH="100vh"
        id="#home"
      >
        <Container maxW="1280px">
          <Center pt="200px" pb="300px">
            <Box maxW="1000px" mx="auto" textAlign="center">
              <Heading variant="primary" fontSize="52px">
                <Highlight
                  query="Unlock Your Profit:"
                  styles={{ color: "#0A1726" }}
                >
                  Unlock Your Profit: Empower Your Investment Journey With Grow
                  My Fund
                </Highlight>
              </Heading>
              <Text maxW="920px" mx="auto" variant="primary" mt="40px">
                Investing with crypto traders can be a great way to diversify
                your portfolio and potentially earn high returns. These traders
                use their expertise and advanced trading tools to navigate the
                volatile crypto markets and generate profits for their clients.
                Additionally, keep in mind that investing in crypto is
                inherently risky and you should only invest what you can afford
                to lose. Overall, investing with crypto traders can be a great
                option for those interested in the potentially lucrative world
                of cryptocurrency.
              </Text>
              <ButtonGroup mt="30px">
                <Button variant="primary" rightIcon={<ArrowForwardIcon />}>
                  Invest Now
                </Button>
                <Button>Contact Us</Button>
              </ButtonGroup>
            </Box>
          </Center>
        </Container>
      </Box>
      <CoinStakingStats />
      <Box as="section" my="200px">
        <Container maxW="1280px">
          <Flex justify="space-evenly" align="center">
            <USDTLogo />
            <BUSDLogo />
            <Flex align="center" gap="10px">
              <TUSDCoin />
              <Text as="strong" fontSize="25px" color="darkTeal">
                USDT
              </Text>
            </Flex>
            <Divider
              orientation="vertical"
              height="80px"
              borderColor="#C5C5C5"
              borderWidth="1.5px"
            />

            <Flex align="center" gap="10px">
              <BUSDCoin />
              <Text as="strong" fontSize="25px" color="secondary">
                BUSD
              </Text>
            </Flex>
            <USDTLogo />
            <BUSDLogo />
          </Flex>
        </Container>
      </Box>
      <Box as="section">
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Heading as="h2" variant="icon" maxW="520px" w="100%">
              <Highlight
                query="making our users more wealthy"
                styles={{
                  color: "var(--chakra-colors-primary-500)",
                  whiteSpace: "normal",
                }}
              >
                Providing funds growing platform, making our users more wealthy
              </Highlight>
            </Heading>
            <Text maxW="520px" w="100%">
              Our team is made up of experienced trades who have joined together
              with Grow My Fund to share their skills to help others have a
              sustainable form of passive income. After years of successfully
              trading with their own funds they are bringing their skills ti the
              public.
            </Text>
          </Flex>
        </Container>
        <Flex align="center" justify="center" mt="40px">
          <Box maxW="540px" w="100%" pl="20px" position="relative">
            <Flex
              wrap="wrap"
              maxW="420px"
              align="center"
              columnGap="20px"
              ml="auto"
            >
              <Box>
                <PlatformStats label="Trading Years" value="4+" />
                <PlatformStats
                  label="Users"
                  value="999+"
                  dark
                  color="secondary"
                />
              </Box>
              <Box pt="45px">
                <PlatformStats
                  label="Revenue"
                  value="$ 100K"
                  dark
                  color="primary.500"
                />
                <PlatformStats label="Chains" value="2" />
              </Box>
            </Flex>
            <Image
              position="absolute"
              zIndex="-1"
              top="0"
              left="0"
              w="100%"
              src={prviderBg}
            />
          </Box>
          <Box position="relative" flex="1">
            <Image src={laptopImg} w="100%" />
          </Box>
        </Flex>
      </Box>
      <Box as="section" bg="secondary" py="115px" mt="80px" id="#how-it-works">
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Heading color="white" as="h2" variant="icon" maxW="520px" w="100%">
              <Highlight
                query="How It Works?"
                styles={{
                  color: "var(--chakra-colors-primary-500)",
                  whiteSpace: "normal",
                }}
              >
                Know the process, How It Works?
              </Highlight>
            </Heading>
            <Text maxW="520px" w="100%" variant="bright">
              Get started by signing up and securely depositing funds. Enter
              your chosen amount and enjoy the rewards as your investments grow
              with expert crypto traders. Experience wealth-building through our
              user-friendly platform.
            </Text>
          </Flex>
          <Flex gap="25px" mt="60px">
            <HowItWorksCard
              bg="blue"
              count="1"
              title="Register"
              disc="To begin, sign up on our site by providing essential details"
            />
            <HowItWorksCard
              bg="yellow"
              count="2"
              title="Deposit"
              disc="After registration, deposit funds securely into your account to start investing and growing your wealth."
            />
            <HowItWorksCard
              bg="pink.300"
              count="3"
              title="Stake Amount"
              disc="Enter the amount you want to stake by selecting either USDT or BUSD for your investment."
            />
            <HowItWorksCard
              bg="purple.300"
              count="4"
              title="Claim Reward"
              disc="Claim your rewards and enjoy the fruits of your investment as your funds grow with the success of the traders."
            />
          </Flex>
        </Container>
      </Box>
      <Flex as="section" gap="100px" align="center" py="150px" id="#stake-info">
        <Box flex="1">
          <Box maxW="500px" ml="auto">
            <Heading as="h2" variant="icon" maxW="520px" w="100%">
              <Highlight
                query="Info"
                styles={{
                  color: "var(--chakra-colors-primary-500)",
                  whiteSpace: "normal",
                }}
              >
                Staking Info
              </Highlight>
            </Heading>
            <List my="35px" spacing="15px">
              <StakeListItem
                icon={<StakedAmountIcon />}
                label="Staked amount will be locked for 30 days."
                bg="#1932B91A"
              />
              <StakeListItem
                icon={<InvestorPercentIcon />}
                label="Investor will be able to earn 10% Monthly profit which is 0.33% daily."
                bg="#FFBE2A1A"
              />
              <StakeListItem
                icon={<UserIcon />}
                label="User must withdraw reward after 7 days."
                bg="#60B0461A"
              />
              <StakeListItem
                icon={<WithdrawIcon />}
                label="On withdrawing reward after 30 days, user will get initial capital plus rewards."
                bg="#E2886C1A"
              />
              <StakeListItem
                icon={<WalletIcon />}
                label="Max 100 investments are allowed on a single wallet."
                bg="#C387F21A"
              />
            </List>
            <Text variant="primary" bg="secondary" rounded="8px" p="16px">
              Note: After 30 days, user must withdraw and re stake to keep
              earning reward.
            </Text>
          </Box>
        </Box>
        <Box flex="1">
          <Image src={stakeInfoImg} w="100%" />
        </Box>
      </Flex>
      <Box
        as="section"
        bg={`#60b04647 url(${calculatorBg}) no-repeat center`}
        bgSize="cover"
        py="150px"
      >
        <Container maxW="1280px">
          <Heading as="h2" variant="icon" w="max-content" mx="auto">
            <Highlight
              query="Calculator"
              styles={{
                color: "var(--chakra-colors-primary-500)",
                whiteSpace: "normal",
              }}
            >
              Investment Calculator
            </Highlight>
          </Heading>
          <Calculator />
        </Container>
      </Box>
      <Box as="section" mt="150px" id="#about">
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Box>
              <Image src={aboutUS} maxW="460px" w="100%" />
            </Box>
            <Box>
              <Heading as="h2" variant="icon" maxW="520px" w="100%">
                <Highlight
                  query="About Us"
                  styles={{
                    color: "var(--chakra-colors-primary-500)",
                    whiteSpace: "normal",
                  }}
                >
                  Know more, About Us
                </Highlight>
              </Heading>
              <Text mt="35px" maxW="520px" w="100%">
                Here at Grow My Fund we have a dedicated team of experienced
                crypto traders. Grow My Fund crypto traders earn money by buying
                and selling cryptocurrencies on various crypto exchanges. They
                leverage their knowledge of markets, trend analysis, technical
                indicators, and other trading strategies to identify
                opportunities and make profitable trades. These traders can also
                use leverage, margin trading, and hedging to enhance their
                positions and overall portfolio value.
                <br />
                <br />
                We offer managed accounts where we use client funds to trade and
                take a percentage of any profits earned, giving you a guaranteed
                percentage income on your deposits. While there are risks
                involved in crypto trading, experienced traders can earn
                substantial profits in this dynamic and rapidly growing business
                market.
              </Text>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as="section" py="200px">
        <Container maxW="620px">
          <Heading as="h2" variant="icon" textAlign="center">
            <Highlight
              query="Asked Questions"
              styles={{
                color: "var(--chakra-colors-primary-500)",
                whiteSpace: "normal",
              }}
            >
              Frequently Asked Questions
            </Highlight>
          </Heading>
          <Accordion mt="50px" allowMultiple>
            {FAQS?.map(({ question, answer }) => (
              <AccordionItem
                border="none"
                bg="#F7F7F7"
                overflow="hidden"
                rounded="8px"
                key={question}
                mb="25px"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      gap="25px"
                      color="secondary"
                      p="22px"
                      _expanded={{ bg: "primary.500", color: "white" }}
                      _hover={{ bg: "primary.500", color: "white" }}
                    >
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                      <Box as="span" flex="1" textAlign="left">
                        {question}
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      p="0 22px 22px"
                      bg="primary.500"
                      color="white"
                    >
                      {answer}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

function StakeListItem({
  icon,
  label,
  bg,
}: {
  icon: JSX.Element;
  label: string;
  bg: string;
}) {
  return (
    <ListItem as={Flex} gap="18px" align="center">
      <Box bg={bg} p="12px" rounded="8px">
        {icon}
      </Box>
      {label}
    </ListItem>
  );
}
function CoinStakingStats() {
  return (
    <Box as="section" mt="-220px">
      <Container maxW="1280px">
        <Grid
          gap="25px"
          gridTemplateColumns="minmax(230px, 240px) 1fr minmax(200px, 270px)"
        >
          <Card bg="secondary" p="14px" gridRow="1/1">
            <Text variant="primary" fontSize="25px" as="strong">
              Stake Now
            </Text>
            <Box bg="white" w="68px" h="10px" rounded="6px" my="8px" />
            <Text
              variant="secondary"
              as="strong"
              fontSize="32px"
              lineHeight="1"
            >
              $ 999
            </Text>
          </Card>
          <Card p="32px" gridRow="1/3">
            <Text color="secondary" fontSize="20px">
              Monthly Stats
            </Text>
            <CoinsGraph />
          </Card>
          <Flex gap="25px" direction={{ md: "column" }} gridRow="1/3">
            <CoinStats
              coin={<DollarCoin />}
              label="Total Reward"
              amount="$100K"
            />
            <CoinStats coin={<BUSDCoin />} label="BUSD Staked" amount="$100K" />
            <CoinStats coin={<TUSDCoin />} label="TUSD Staked" amount="$100K" />
          </Flex>
          <Card p="32px" gridRow="2/4">
            <SvgGraph />
            <Text mt="auto" color="secondary" fontSize="20px">
              Monthly Profit{" "}
              <Text as="span" fontSize="12px" variant="secondary">
                10%
              </Text>
            </Text>
          </Card>
          <Button
            variant="primary"
            gridColumn="2/4"
            color="white"
            rounded="8px"
            rightIcon={<ArrowForwardIcon />}
          >
            Save & earn profit
          </Button>
        </Grid>
      </Container>
    </Box>
  );
}

function SvgGraph() {
  return (
    <Box as="svg" viewBox="0 0 64 64" fontSize="13px">
      <Box
        fill="none"
        stroke="#EEEEEE"
        strokeWidth="2"
        as="circle"
        r="45%"
        cx="50%"
        cy="50%"
      />
      <g>
        <Box
          fill="none"
          stroke="primary.500"
          strokeDasharray={`${40} 140`}
          strokeWidth="5"
          as="circle"
          r="45%"
          cx="50%"
          cy="50%"
        />
        <Box
          as="text"
          x="50%"
          y="50%"
          textAnchor="middle"
          fill="secondary"
          dy=".4em"
          fontWeight="900"
        >
          $ 1M
        </Box>
        <Box
          as="text"
          x="50%"
          y="67%"
          textAnchor="middle"
          fill="#8F92A1"
          dy=".4em"
          fontSize="5px"
        >
          Stake amount
        </Box>
      </g>
    </Box>
  );
}

function CoinStats({
  coin,
  amount,
  label,
  color,
}: {
  coin: JSX.Element;
  amount: string;
  label: string;
  color?: string;
}) {
  return (
    <Card p="16px">
      <Flex gap="16px" align="center">
        {coin}
        <Box>
          <Text fontSize="14px" mb="10px">
            {label}
          </Text>
          <Text
            as="strong"
            variant="secondary"
            fontSize="32px"
            color={color}
            lineHeight="1"
          >
            {amount}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}

function CoinsGraph() {
  const options = {
    colors: ["#F3BA2F", "#26A17B"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };
  const series = [
    {
      name: "BUSD",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "USDT",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
  ];

  return (
    <Chart
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      options={options}
      series={series}
      type="bar"
      height="250px"
      width="100%"
    />
  );
}

function HowItWorksCard({
  count,
  title,
  disc,
  bg,
}: {
  count: string;
  title: string;
  disc: string;
  bg: string;
}) {
  return (
    <Box
      maxW="300px"
      w="100%"
      border="1px solid #EEEEEE"
      rounded="8px"
      textAlign="center"
      p="20px 15px 30px"
    >
      <Avatar size="lg" bg={bg} src={count} name={count} />
      <Heading as="h4" fontSize="20px" my="32px" color="white">
        {title}
      </Heading>
      <Text variant="bright">{disc}</Text>
    </Box>
  );
}

function PlatformStats({
  label,
  value,
  dark,
  color,
}: {
  label: string;
  value: string;
  dark?: boolean;
  color?: string;
}) {
  return (
    <Card
      p="25px 30px"
      bg={color}
      w="max-content"
      textAlign="center"
      gap="6px"
      mb="30px"
    >
      <Text as="strong" fontSize="32px" color={dark ? "white" : "primary.500"}>
        {value}
      </Text>
      <Text as="span" color={dark ? "white" : ""}>
        {label}
      </Text>
    </Card>
  );
}

function Calculator() {
  return (
    <Flex mt="60px" gap="60px">
      <Box maxW="600px" w="100%">
        <InputGroup
          borderColor="primary.500"
          overflow="hidden"
          mb="50px"
          size="lg"
        >
          <Input
            bg="white"
            placeholder="Enter amount"
            focusBorderColor="primary.500"
          />
          <InputRightElement color="secondary">$</InputRightElement>
        </InputGroup>
        <RangeSelector label="amount" color="secondary" amount="$ 10,000.00" />
        <RangeSelector
          label="Daily Reward ( 0.33% )"
          color="#E2886C"
          amount="$ 33.33"
        />
        <RangeSelector
          label="Weekly Reward ( 2.31% )"
          color="#FFBE2A"
          amount="$ 233.33"
        />
        <RangeSelector
          label="Monthly Reward ( 10% )"
          color="#C387F2"
          amount="$ 1000.00"
        />
      </Box>
      <Box maxW="430px" w="100%">
        <Card p="42px 20px">
          <Box position="relative" maxW="270px" mx="auto">
            <Image src={GraphImg} alt="GraphImg" w="100%" h="100%" />
            <Center
              position="absolute"
              top="53%"
              left="51%"
              transform="translate(-50%,-50%)"
              bg="secondary"
              w="115px"
              h="115px"
              rounded="50%"
              flexDirection="column"
              mx="auto"
            >
              <Text variant="primary" as="span">
                Amount
              </Text>
              <Text variant="primary" as="span">
                $ 1000
              </Text>
            </Center>
          </Box>
          <Divider borderStyle="dashed" my="25px" />
          <Flex justify="space-evenly" gap="10px">
            <CalculatedStat color="#E2886C" label="Daily" value="$ 33.33" />
            <CalculatedStat color="#FFBE2A" label="Weekly" value="$ 233.33" />
            <CalculatedStat color="#C387F2" label="Monthly" value="$ 1000.00" />
          </Flex>
        </Card>
      </Box>
    </Flex>
  );
}

function RangeSelector({
  label,
  amount,
  color,
}: {
  label: string;
  amount: string;
  color: string;
}) {
  return (
    <Card p="15px" mb="26px">
      <Flex justify="space-between" mb="15px">
        <Text color="secondary" as="strong">
          {label}
        </Text>
        <Text as="strong">{amount}</Text>
      </Flex>
      <RangeSlider aria-label={["min", "max"]} defaultValue={[0, 30]}>
        <RangeSliderTrack>
          <RangeSliderFilledTrack bg={color} />
        </RangeSliderTrack>
        <RangeSliderThumb bg={color} boxShadow="none" index={1} />
      </RangeSlider>
    </Card>
  );
}

function CalculatedStat({
  color,
  label,
  value,
}: {
  color: string;
  label: string;
  value: string;
}) {
  return (
    <Box textAlign="center">
      <Box w="20px" h="20px" mx="auto" bg={color}></Box>
      <Text color="secondary" my="10px">
        {label}
      </Text>
      <Text fontSize="20px" lineHeight="1" color={color}>
        {value}
      </Text>
    </Box>
  );
}
