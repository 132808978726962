import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CreateWallet from "views/createWallet";
import Landing from "views/landing";
import Login from "views/login";
import SignUp from "views/signup";
import ForgetPassword from "components/forget-password";

const routes = [
  {
    path: "/",
    Component: Landing,
  },

  {
    path: "/signup",
    Component: SignUp,
  },
  {
    path: "/login",
    Component: Login,
  },
  {
    path: "/forget-password",
    Component: ForgetPassword,
  },
  {
    path: "/create-wallet",
    Component: CreateWallet,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route
          key={path}
          element={
            // isPrivate ? (
            //   <PrivateRoute component={<Component />} />
            // ) : (
            <Component />
            // )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
