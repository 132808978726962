import React from "react";
import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import FooterBg from "assets/images/footer-bg.png";
import logo from "assets/images/footer-logo.svg";
import contractImg from "assets/images/footer-contract.png";
import { MENU } from "utils/const";
import { Link } from "react-router-dom";
import { DiscordIcon, TwitterIcon } from "assets/icons";

export default function Footer() {
  const intoView = (id: string) => {
    const elem = document.getElementById(id);
    elem?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box
      bg={`url(${FooterBg}) no-repeat center right`}
      bgSize="cover"
      py="60px"
    >
      <Container maxW="1280px" position="relative">
        <Image
          src={contractImg}
          maxW="350px"
          position="absolute"
          top="-60px"
          transform="translateY(-50%)"
          zIndex="1"
        />
        <Image src={logo} mx="auto" />
        <Flex as="nav" my="30px" gap="25px" align="center" justify="center">
          {MENU.map(({ label, path }) => (
            <Text
              onClick={() => intoView(path)}
              as={Link}
              to={path}
              key={label}
              variant="primary"
            >
              {label}
            </Text>
          ))}
        </Flex>
        <Flex justify="center" gap="20px" mb="30px">
          <a
            href="https://twitter.com/growmyfund"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://discord.gg/yY446BmfQH"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
        </Flex>
        <Text variant="primary" textAlign="center">
          Copyright © 2023. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
}
