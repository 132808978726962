import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import capsuleIcon from "assets/images/capsule-icon.png";
const theme = {
  fonts: {
    body: "Nexa",
    heading: "Nexa",
    label: "Nexa",
  },
  colors: {
    primary: {
      50: "#9BD08A",
      100: "#8FCB7C",
      200: "#83C56D",
      300: "#77BF5F",
      400: "#6AB950",
      500: "#60B046",
      600: "#9BD08A",
      700: "#50923A",
      800: "#488334",
      900: "#40752F",
    },
    secondary: "#0A1726",
    text: "#6C747D",
    secondaryText: "#EEEEEE",
    background: "#ffffff",
    error: "#E84A4A",
    heading: "#0A1726",
    darkOrange: "#F3BA2F",
    darkTeal: "#26A17B",
  },
  components: {
    Text: {
      baseStyle: { color: "text" },
      variants: {
        primary: { color: "white" },
        secondary: { color: "primary.500" },
        bright: { color: "secondaryText" },
      },
    },
    Heading: {
      baseStyle: { color: "heading" },
      variants: {
        primary: { color: "white" },
        icon: {
          position: "relative",
          zIndex: "1",
          _before: {
            content: `url(${capsuleIcon})`,
            display: "block",
            position: "absolute",
            left: "-35px",
            zIndex: "-1",
            top: "-32px",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "400",
        borderRadius: "40px",
        border: "1px solid transparent",
      },
      variants: {
        solid: {
          borderColor: "primary.500",
          color: "primary.500",
          bg: "white",
          p: "18px 24px",
          h: "auto",
          minW: "135px",
          _hover: {
            bg: "primary.500",
            color: "white",
            borderColor: "white",
          },
        },
        primary: {
          borderColor: "secondary",
          color: "primary.500",
          bg: "secondary",
          p: "18px 24px",
          h: "auto",
          minW: "135px",
          _hover: {
            bg: "primary.500",
            color: "secondary",
          },
        },
        secondary: {
          borderColor: "primary.500",
          color: "white",
          bg: "primary.500",
          p: "18px 24px",
          h: "auto",
          minW: "135px",
          _hover: {
            bg: "white",
            color: "primary.500",
          },
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
          borderRadius: "8px",
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "background",
        color: "text",
        fontWeight: "400",
      },
    },
  },
};

export default extendTheme(
  theme,
  withDefaultColorScheme({
    colorScheme: "primary",
  })
);
