import React from "react";
import {
  RegisterOptions,
  UseFormRegister,
  Path,
  FieldValues,
} from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputProps,
} from "@chakra-ui/react";

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register: UseFormRegister<TFormValues>;
  isError?: boolean;
  placeholder: string;
  errorMessage?: string;
  helperText?: string;
  type?: string;
  inputProps?: InputProps;
  formProps?: FormControlProps;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormInput = <TFormValues extends Record<string, any>>({
  name,
  register,
  rules,
  isError,
  placeholder,
  errorMessage,
  type,
  inputProps,
  formProps,
  helperText,
}: FormInputProps<TFormValues>): JSX.Element => {
  return (
    <FormControl {...formProps} isInvalid={isError} mb="20px">
      <Input
        bg="white"
        type={type}
        focusBorderColor="primary.500"
        {...register(name, rules)}
        placeholder={placeholder}
        {...inputProps}
        borderRadius="8px"
        size="lg"
      />
      <FormHelperText fontSize="12px" fontStyle="italic" color="orange.400">
        {helperText}
      </FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
